/* top level arrangement */
.page-wrapper {
    padding: 100px 20px 20px
}

.row {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
}

.column {
    display: flex;
    flex-direction: column
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-base {
    align-items: baseline;
}

.align-end{
    align-items: flex-end;
}

.text-center{
    text-align: center;
}

.full-width{
    width: 100%
}

/* base styles */
h1{
 font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 font-size: 50px;
 letter-spacing: 0.2px;
 color: grey
}

h2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    letter-spacing: 0.2px;
    color: grey;
    margin-bottom: 20px;
}

h3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 25px;
    letter-spacing: 0.2px;
    color: grey

}

p, li {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    margin-bottom: 20px;
}

ul {
    padding-bottom: 10px;
}

.bold {
    font-weight: 600;
}

.nextLine {
    margin-top: 0 !important
}

/* buttons */
.main-btn{
    border: none;
    padding: 18px;
    width: 240px;
    background-color: lightblue;
    color: white;
    margin: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
}

.main-btn:hover{
    background-color: #8fcee2;
}

a.main-btn {
    text-decoration: none;
    text-align: center;
    background-color: rgb(193, 191, 191);
}

.back{
    background-color: lightgray;
}
.back:hover{
    background-color: rgb(193, 191, 191);;
}

.show-btn{
    border: none;
    padding: 5px;
    width: 125px;
    background-color: lightgrey;
    color: white;
    margin: 10px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
}

.show-btn:before {
    content: "Show me how"
}

.show-btn:hover{
    background-color: lightblue;
}

.disabled, .disabled:hover {
    background-color: rgb(193, 191, 191);
    cursor: default;
}

.inv-btn{
    border: none;
    background-color: unset;
}

.advanced-text:before {
    content: "Do it for me"
}

.disabled:hover:before {
    content: "Coming soon";
    font-size: 17px;
}

.error {
    background-color: rgb(199, 62, 62);
}

button:focus{
    outline: none;
}

button.close{
    margin-right: 20px;
    font-weight: 400;
}

.play-btn{
    border: none;
    border-radius: 100%;
    background-color: transparent;
    color: lightgray;
    cursor: pointer;
    font-size: 18px;
    line-height: 0;
    font-weight: 400;
    width: 30px;
    height: 30px;
}
.play-btn:hover{
    background-color: #d2cfcf;
    color: white;
    box-shadow: 2px 2px 2px #a7a4a4;
    font-weight: 500;
    font-size: 21px;
}
.first {
    background-color: turquoise;
    
    animation: pulse 1s infinite;
}
@keyframes pulse {
    0% {
      background-color: #fff;
      color: lightgray;
    }
    50% {
      background-color: turquoise;
      color: white;
    }
    100% {
        background-color: #fff;
        color: lightgray;
    }
  }

/* forms */
.instructions {
    margin: 50px;
}

.instruction-row{
    display: flex;
    flex-direction: row;
    width: 450px;
    justify-content: space-between;
}
.GA-form{
    width: 100%
}
.GA-form label {
    margin-left: 10px;
    padding: 10px 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    max-width: 450px;
    justify-content: space-between;
}

.GA-form input {
    margin: 0 10px;
    border: 1px solid darkgrey;
}

.GA-form input:focus{
    outline: 3px solid lightblue;
    border: none;
}

.GA-form input::placeholder{
    color: lightgray
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: lightgray;
}
  ::-moz-placeholder { /* Firefox 19+ */
    color: lightgray;
}
  :-ms-input-placeholder { /* IE 10+ */
    color: lightgray;
}
  :-moz-placeholder { /* Firefox 18- */
    color: lightgray;
}

.form{
    margin-left: 0px;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    font-weight: 300;
}

.form-row {
    margin-left: 10px;
    padding: 10px 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    max-width: 470px;
    justify-content: space-between;
}

.form-row-extra {
    margin-left: 10px;
    padding: 10px 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    justify-content: flex-start

}

.extra-container{
    display: flex;
    flex-direction: row;
    max-width: 470px;
    justify-content: space-between;
    width: 100%;
}

.signUp-form{
    max-width: 600px;
}

.signUp-form input[type="text"], input[type="email"], label{
    width: 100%;
}

.submitError {
    margin-left: 10px;
    color: red;
}

.addError{
    margin: 10px
}

/* checkbox */
[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
   }

[type=checkbox]{
    width: 20px;
    height: 20px;
    border: solid 1px #cccccc;
    margin-right: 8px;
    position: relative;
 }

 [type=checkbox]:checked::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: lightblue;
    position: absolute;
    top: 0px;
    left: 0px;
  }

/* scores */
.reasonable{
    color: #859fb7;
    font-weight: 500;
    font-size: 20px;
}

.good {
    color: #337ab7;
    font-weight: 500;
    font-size: 20px;
}

.bad {
    color: #ef6262;
    font-weight: 500;
    font-size: 20px;
}

.score {
    margin-left: 3px;
}

.reportCard {
    font-size: 100px;
    font-family: 'lato', 'geneva', 'verdana';
    margin-top: 0;
    margin-bottom: 20px;
    color: green;
}

.score-A{
    color: #a44bf7
}
.score-B{
    color: #823bc5
}
.score-C{
    color: #5c298c
}
.score-D{
    color: #351750
}
.score-F{
    color: #ef6262
}

/* progress bar */
.progress-line{
    height: 60px
}
.progress-section{
    height: 5px;
    width: 100%;
    background-color: lightgrey;
    background-position: right bottom;
}
.progress-active{
    background: linear-gradient(to right, darkgrey 50%, lightgray 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: all .5s ease-out;
}


/* sections */
.home-buttons-container{
    margin-top: 25px;
}

.actions{
    margin: 20px 0;
}

.analysis-section {
    padding: 40px 0 50px;
    font-weight: 100;
    width: 65%;
    max-width: 1000px;
}

.result{
    font-weight: 400;
    font-size: 19px;
}

.gif-container{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 50px 100px 0 0;
}

.section{
    margin: 0px 100px 0 0;
}

.gif-player{
    max-width: 500px;
}

.show-me-label{
    color: lightgray;
    cursor: pointer;
    font-size: 14px;
    line-height: 0;
    font-weight: 400;
    font-style: italic;
    margin: 0 0 15px;
    align-self: center;
}

.show-label-off{
    display: none
}

.label-btn-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.device-icon{
    margin-right: 5px;
    vertical-align: text-bottom;
}

.results-section {
    padding: 20px 0;
    font-weight: 100;
}

.results-section .title{
    font-weight: 400;
    color: #8fcee2;
    font-size: 23px;
}

.results-container {
    padding: 40px 0;
    width: 65%;
}


/* footer */
.footer{
    margin-top: 50px;
    margin-bottom: 20px;
}

.footer .features{
    margin-top: 40px;
}

.footer .features p{
    color: #8fcee2;
    font-size: 22px;
}

.footer:hover h3{
    color: #8fcee2;
}

.signup{
    margin-top: 40px;
}

.logo {
    width: 80%;
    max-width: 280px;
    margin-bottom: 30px;
}

.spacer {
    margin-top: 20px;
    margin-bottom: 20px;
}
#gitButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.25px;
    transition: all 200ms linear;
}
#gitButton:hover {
    width:58px;
    transition: all 200ms linear;
}

/* responsive */
@media screen and (max-width: 1180px){
    .gif-container{
        position: fixed;
        display: unset;
        margin: unset;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(97, 97, 97, 0.5)
    }
    .noscroll{
        overflow: hidden;
    }
    button.close{
        margin-right: unset;
        position: fixed;
        top: 5%;
        left: calc(50% - 330px);
        opacity: 0.9;
        color: white;
    }
    .gif-player{
        position: fixed;
        max-width: 500px;
        width: 90%;
        top: 10%;
        left: calc(50% - 300px);
    }
    .analysis-section {
        width: 100%;
    }
}

@media screen and (max-width: 690px){
    .row, .form-row, .form-row-extra, .extra-container{
        flex-direction: column;
    }
    .progress-line{
        flex-direction: row;
    }
    h1, h2, h3 {
        text-align: center;
    }
    .justify-center{
        align-items: center;
    }
    .instructions{
        width: 100%;
        margin: 40px 0 0;
    }
    .instruction-row{
        width: 100%
    }
    .GA-form label{
        flex-direction: column;
        margin-left: 0;
    }
    .extra-container{
        width: 100%
    }
    .form {
        flex-direction: row !important;
        align-items: center;
    }
    .main-btn{
        width: 190px
    }
    .error {
        background-color: rgb(199, 62, 62) !important;
    }
    .highlight-btn{
        align-items: flex-end;
    }
    .home-buttons-container{
        align-items: center;
        flex-direction: column-reverse !important;
    }
    .submit-btn-container{
        align-items: center;
        flex-direction: column-reverse !important
    }
    .gif-container{
        position: fixed;
        display: unset;
        margin: unset;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(97, 97, 97, 0.5)
    }
    .noscroll{
        overflow: hidden;
    }
    button.close{
        margin-right: unset;
        position: fixed;
        top: 5%;
        left: 20px;
        opacity: 0.9;
        color: white;
    }
    .gif-player{
        position: fixed;
        max-width: unset;
        width: 90%;
        top: 10%;
        left: 5%;
    }
    .results-container{
        width: 100%
    }
}
